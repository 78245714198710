const models = require('./models');

module.exports = {
    format: function (type) {

        switch (type) {
            case "users": {
                return this.loopItems(models.userMaps());
            }
            case "teams": {
                return this.loopItems(models.teamMaps());
            }
            case "roles": {
                return this.loopItems(models.roleMaps());
            }
            case "deviceUsers": {
                return this.loopItems(models.deviceUserMaps());
            }
            case "events": {
                return this.loopItems(models.eventMaps());
            }
            case "eventCategories": {
                return this.loopItems(models.eventCategoryMaps());
            }
            case "permissions": {
                return this.loopItems(models.permissionMaps());
            }
            case "routeResources": {
                return this.loopItems(models.routeResourcesMap());
            }
            case "ticketTypes": {
                return this.loopItems(models.ticketTypesMap());
            }
            case "tickets": {
                return this.loopItems(models.ticketsMap());
            }
            case "refund": {
                return this.loopItems(models.refundMap)
            }
            case "sellersProgress": {
                return this.loopItems(models.sellersProgressMap());
            }
            case "cashOuts": {
                return this.loopItems(models.cashOutMap());
            }
            default:
                break;
        }
    },
    loopItems: function (map) {

        return Object.keys(map).map(key => ({
            field: key,
            header: typeof map[key] === 'object' ? map[key].header : map[key],
            type: typeof map[key] === 'object' ? map[key].type : null
        }));
    },
    remappedDataItems: function (dataItems, map) {

        return dataItems.map(data => {
            const mappedData = {};
            for (const key in map) {
                if (data.hasOwnProperty(key)) {
                    mappedData[map[key]] = data[key];
                }
            }
            return mappedData;
        });
    }
}