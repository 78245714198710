// SessionContext.js
import { createContext, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { config } from "../../config/config";

import { postData, getData } from '../../services/api';

const SessionContext = createContext();

export const useSession = () => useContext(SessionContext);

export const SessionProvider = ({ children }) => {
    const [session, setSession] = useState(() => {
        const sessionString = localStorage.getItem('session');
        return sessionString ? JSON.parse(sessionString) : null;
    });

    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const login = async (userData) => {
        try {
            const data = {
                ...config.tokenGeneration,
                username: userData.username,
                password: userData.password
            };

            const tokenResult = await postData(null, "auth/token", data);

            const userResult = await getData({ token: tokenResult }, `client-users/${userData.username}`)
            const sessionObject = { token: tokenResult, user: userResult };

            setSession(sessionObject);
            localStorage.setItem("session", JSON.stringify(sessionObject));
            navigate("/home")
        } catch (err) {
            setError(err.message);
        }
    };

    const logout = async () => {
        // Logic to clear session data
        setSession(null);
        localStorage.removeItem("session");
        navigate("/login")
    };


    return (
        <SessionContext.Provider value={{ session, login, logout, error, setError }}>
            {children}
        </SessionContext.Provider>
    );
};