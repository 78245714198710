import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import "./assets/pageNotFound.css"

function NotFoundPage() {

    const navigate = useNavigate();

    useEffect(() => {
        // Set a timeout to redirect to home after 2 seconds
        const timer = setTimeout(() => {
            navigate('/home');
        }, 2000);

        // Clear the timeout if the component unmounts
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="not-found-container">
            <h1 className="not-found-title">404</h1>
            <p className="not-found-message">Page Not Found</p>
            <p className="redirect-message">You will be redirected to the home page in a few seconds...</p>
        </div>
    );
}

export default NotFoundPage;