import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

export default function TicketRefundModal(modalData: any) {
    const [formData] = useState(modalData.selectedDataItem);
    const [modalHeader, setModalHeader] = useState("");

    const toast = useRef<Toast>(null);

    useEffect(() => {
        setModalHeader("Information");
    }, []);

    return (
        <div className="modal-container">
            <Toast ref={toast} />
            <div className="modal">
                <div className="modal-header">
                    <span className="modal-title">{modalHeader}</span>
                    <span className="modal-close" onClick={modalData.closeModal}>&times;</span>
                </div>
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Deleted By</label>
                        <input
                            className="text-input"
                            readOnly={true}
                            type="text"
                            id="ref"
                            name="ref"
                            value={formData.deletedBy}
                        />
                    </div>

                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Deleted At</label>
                        <input
                            className="text-input"
                            readOnly={true}
                            type="text"
                            id="ref"
                            name="ref"
                            value={dayjs(formData.deletedAt).format("DD-MM-YYYY HH:mm")}
                        />
                    </div>
                </div>
                <div className="footer">
                    <button className="save-button" type="button" style={{ visibility: "hidden" }} onClick={() => { }}>Save</button>
                    <button type="button" className="cancel-button" onClick={modalData.closeModal}>Cancel</button>
                </div>
            </div>
        </div>
    );
}
