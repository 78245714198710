import { useEffect, useState, useRef } from "react";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { MultiSelect } from "primereact/multiselect";

import { getData, postData } from "./services/api";
import { useSession } from "./assets/auth/SessionContext";
import Table from "./components/Table";
import datePicker from "./utils/date-picker";
import CashOutModal from "./components/CashOutModal";

import dayjs, { Dayjs } from "dayjs";

import CustomDateTimePicker from "./services/CustomDateTimePicker";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import models from "./utils/models";

const TicketSales = () => {

    const dateFormat = 'dd-mm-yy';

    const toast = useRef<Toast>(null);

    const [data, setData] = useState<{ isScanned: boolean, transactions: any, email: string }[]>([]);
    const [type] = useState("sellersProgress")
    const [eventDateFrom, setEventDateFrom] = useState<Date>(new Date(dayjs().subtract(1, "month").toDate()));
    const [eventDateTo, setEventDateTo] = useState<Date>(new Date());
    const [ticketDateFrom, setTicketDateFrom] = useState<Dayjs | null>(dayjs().subtract(1, "month"));
    const [ticketDateTo, setTicketDateTo] = useState<Dayjs | null>(dayjs());
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedDeviceUsers, setSelectedDeviceUsers] = useState([]);
    const [events, setEvents] = useState([]);
    const [deviceUsers, setDeviceUsers] = useState([]);
    const [isCashOutEnabled, setIsCashOutDisabled] = useState(true);
    const [cashOut, setCashOut] = useState(null);
    const [cashOutModalIsVisible, setCashOutModalIsVisible] = useState(false);

    const { session } = useSession();

    const setEventDate = (fieldName: string, value: Date) => {
        datePicker.max1Month({ fieldName, value, from: eventDateFrom, to: eventDateTo, setFrom: setEventDateFrom, setTo: setEventDateTo });
    };

    const setTicketDate = (id: string, value: Dayjs | null) => {
        datePicker.max1MonthCustom({ fieldName: id, value, from: ticketDateFrom, to: ticketDateTo, setFrom: setTicketDateFrom, setTo: setTicketDateTo });
    };

    const handleDeviceUserChange = (e: any) => {
        if (e.value.length <= 5) {
            setSelectedDeviceUsers(e.value);
        }

        if (e.value.length === 0) {
            setIsCashOutDisabled(true);
        }
    };

    const handleCashOut = () => {
        setCashOutModalIsVisible(true);
    }

    const handleCashOutHide = (update: any | null) => {
        setCashOutModalIsVisible(false);

        if (update && update.message && toast.current) {
            toast.current.show({ severity: 'success', summary: update.message, detail: "" });
        }
    }

    useEffect(() => {
        getData(session, `events`, `dateFrom=${dayjs(eventDateFrom).format("YYYY-MM-DD")}&dateTo=${dayjs(eventDateTo).format("YYYY-MM-DD")}&dropdown=true`).then(events => {
            const eventsForMultiSelect = events.map((x: { name: any; ref: any }) => ({
                name: x.name,
                value: x.ref
            }));

            setEvents(eventsForMultiSelect);
        });

        getData(session, `device-users`, "dropdown=true").then(deviceUsers => {
            const eventsForMultiSelect = deviceUsers.map((x: { displayName: any; ref: any }) => ({
                name: x.displayName,
                value: x.ref
            }));

            setDeviceUsers(eventsForMultiSelect);
        });
    }, [eventDateFrom, eventDateTo]);

    useEffect(() => {
        setData([]);
        setSelectedEvent(null);
    }, [eventDateFrom, eventDateTo]);

    useEffect(() => {
        setData([]);
        fetchData();
    }, [selectedDeviceUsers, selectedEvent, ticketDateFrom, ticketDateTo]);

    const fetchData = () => {

        if (!selectedEvent && selectedDeviceUsers.length === 0) {
            return;
        }

        interface BodyType {
            dateFrom: Dayjs;
            dateTo: Dayjs;
            eventRef?: string | null;
            deviceUsers?: any[];
        }

        const body: BodyType = {
            dateFrom: dayjs(ticketDateFrom),
            dateTo: dayjs(ticketDateTo)
        };

        if (selectedDeviceUsers.length !== 0) {
            body.deviceUsers = selectedDeviceUsers;
        }

        if (selectedEvent) {
            body.eventRef = selectedEvent
        }

        postData(session, "reports/sellers-progress-report", body, null).then(ticketsData => {
            setData(ticketsData?.data?.sellersProgress);

            if (selectedDeviceUsers.length === 1 && ticketsData?.data?.sellersProgress?.length > 0) {
                setIsCashOutDisabled(false);
                setCashOut({ ...ticketsData.data.cashOutInformation, deviceUserFullName: ticketsData?.data?.sellersProgress[0]?.fullName, deviceUserRef: selectedDeviceUsers[0] });
                return;
            }

            setIsCashOutDisabled(true);
        }).catch(err => {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
            }
        });
    };

    return (
        <div className="flex">
            <Toast ref={toast} />
            <Dialog visible={cashOutModalIsVisible} onHide={() => { }}>
                <CashOutModal
                    selectedDataItem={cashOut}
                    closeModal={handleCashOutHide}
                />
            </Dialog>
            <div className="table-container">
                <div className="card flex flex-wrap gap-3 p-fluid select-dates">
                    <div className="flex1">
                        <label htmlFor="buttondisplay" className="font-bold block mb-2">
                            From
                        </label>
                        <Calendar dateFormat={dateFormat} className="calendar" value={eventDateFrom} onChange={(e) => setEventDate("from", e.value || new Date())} showIcon />
                    </div>
                    <div className="flex1">
                        <label htmlFor="buttondisplay" className="font-bold block mb-2">
                            To
                        </label>
                        <Calendar value={eventDateTo} dateFormat={dateFormat} className="calendar" onChange={(e) => setEventDate("to", e.value || new Date())} showIcon />

                    </div>
                    <div className="flex1 top-bar-filtering">
                        <label htmlFor="text-label" className="font-bold block mb-2">Select Event</label>
                        <Dropdown
                            onChange={(e) => { setSelectedEvent(e.value); }}
                            options={events}
                            optionLabel="name"
                            placeholder="Select Event"
                            className="dropdown"
                            value={selectedEvent}
                            showClear
                            filter
                        />
                    </div>
                </div>

                <div className="card flex flex-wrap gap-3 p-fluid select-dates">
                    <div className="flex1">
                        <label htmlFor="buttondisplay" className="font-bold block mb-2">
                            From
                        </label>
                        <CustomDateTimePicker id="from" value={ticketDateFrom} onChange={setTicketDate} />
                    </div>
                    <div className="flex1">
                        <label htmlFor="buttondisplay" className="font-bold block mb-2">
                            To
                        </label>
                        <CustomDateTimePicker id="to" value={ticketDateTo} onChange={setTicketDate} />

                    </div>
                    <div className="flex1 top-bar-filtering">
                        <label htmlFor="text-label" className="font-bold block mb-2">Sellers</label>
                        <MultiSelect value={selectedDeviceUsers} onChange={handleDeviceUserChange} options={deviceUsers} optionLabel="name"
                            filter placeholder="Select Seller" className="dropdown" />
                    </div>
                    <div className="flex1">
                        <Button className="save-button cash-out" disabled={isCashOutEnabled} onClick={handleCashOut} label="Cash Out" />
                    </div>
                </div>

                <div>
                    <div className="table-container">
                        <Table
                            data={data}
                            type={type}
                            editButtonVisible={false}
                            addButtonVisible={false}
                            removeButtonVisible={false}
                            actionsVisible={false}
                            exportButtonVisible={true}
                            map={models.sellersProgressMap()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TicketSales;
