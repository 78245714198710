import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

interface ScannedData {
    _id: string;
    scannedTickets: number;
    notScannedTickets: number;
    ticketTypeName: string;
}

interface ScannedBarGraphProps {
    data: ScannedData[];
}

const ScannedBarGraph: React.FC<ScannedBarGraphProps> = ({ data }) => {
    const formattedData = data.map((item) => ({
        name: item.ticketTypeName,
        scanned: item.scannedTickets,
        notScanned: item.notScannedTickets,
    }));

    const generateHSLColor = (i: number, total: number) => {
        const hue = (i * 360) / total;
        return `hsl(${hue}, 70%, 50%)`;
    };

    const colors = formattedData.map((_, i) => generateHSLColor(i, formattedData.length));

    const chartData = {
        labels: formattedData.map((item) => item.name),
        datasets: [
            {
                label: 'Scanned Tickets',
                data: formattedData.map((item) => item.scanned),
                backgroundColor: colors,
            },
            {
                label: 'Not Scanned Tickets',
                data: formattedData.map((item) => item.notScanned),
                backgroundColor: colors.map(color => color.replace('50%', '80%')),
            },
        ],
    };

    const options = {
        indexAxis: 'y' as const,
        scales: {
            x: {
                stacked: true,
                beginAtZero: true,
                ticks: {
                    callback: function (tickValue: any, index: number, ticks: any[]) {
                        if (Number.isInteger(tickValue)) {
                            return tickValue.toString();
                        } else {
                            return '';
                        }
                    },
                },
            },
            y: {
                stacked: true,
                ticks: {
                    font: {
                        size: 12,
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const scannedTickets = Math.round(formattedData[context.dataIndex].scanned);
                        const notScannedTickets = Math.round(formattedData[context.dataIndex].notScanned);
                        return [
                            `Scanned Tickets: ${scannedTickets}`,
                            `Not Scanned Tickets: ${notScannedTickets}`,
                            `Total: ${scannedTickets + notScannedTickets}`
                        ];
                    },
                },
            },
            legend: {
                display: false,
            },
        },
        maintainAspectRatio: false,
        barThickness: 30,
    };

    const containerHeight = Math.max(150, formattedData.length * 50); // Ensure a minimum height to avoid issues with small datasets

    return (
        <div style={{ display: 'flex', height: containerHeight }}>
            <div style={{ flex: 1 }}>
                <Bar data={chartData} options={options} />
            </div>
            <div style={{ flex: 0.3, padding: '10px' }}>
                {formattedData.map((item, index) => (
                    <div key={index} style={{ marginBottom: '10px' }}>
                        <div style={{ backgroundColor: colors[index], height: '10px', width: '10px', display: 'inline-block', marginRight: '5px' }}></div>
                        <p style={{ display: 'inline' }}>
                            {item.name} - Scanned: {Math.round(item.scanned)}, Not Scanned: {Math.round(item.notScanned)}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ScannedBarGraph;
