import { ChevronFirst, ChevronLast } from "lucide-react"
import logo from "../assets/logo.png"
import profile from "../assets/profile.png"
import React, { createContext, useContext, useState } from "react"

import { useNavigate } from 'react-router-dom';
import { useSession } from '../assets/auth/SessionContext';

const SidebarContext = createContext();

export default function Sidebar({ expanded, setExpanded, children }) {
    const session = JSON.parse(localStorage.getItem("session"));
    // const [expanded, setExpanded] = useState(true);

    return (
        <aside className="h-screen fixed">
            <nav className="h-full flex flex-col bg-white border-r shadow-sm">
                <div className="p-4 pb-2 flex justify-between items-center">
                    <img src={logo} alt="" className={`overflow-hidden transition-all ${expanded ? "w-32" : "w-0"}`} />
                    <button onClick={() => setExpanded(curr => !curr)} className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100">
                        {expanded ? <ChevronFirst /> : <ChevronLast />}
                    </button>
                </div>

                <SidebarContext.Provider value={{ expanded }}>
                    <ul className="flex-1 px-3">{children}</ul>
                </SidebarContext.Provider>

                <div className="border-t flex p-3">
                    <img src={profile} alt="" className="w-10 h-10 rounded-md" />
                    <div className={`flex justify-between items-center overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"} `}>
                        <div className="leading-4">
                            <h4 className="font-semibold">{session?.user?.displayName}</h4>
                            <span className="text-xs text-gray-600">{session?.user?.email}</span>
                        </div>
                    </div>
                </div>
            </nav>
        </aside>
    );
}

export function SidebarItem({ id, icon, text, active, path, action, children, isOpen, onToggle }) {
    const { expanded } = useContext(SidebarContext);
    const { logout } = useSession();
    const navigate = useNavigate();

    const handleClick = (e) => {
        e.stopPropagation();

        if (children) {
            onToggle(id);
        } else if (path) {
            navigate(path);
        } else if (action === "logout") {
            logout();
        }
    };

    return (
        <li>
            <div
                onClick={handleClick}
                className={`max-height-side-panel relative flex items-center py-2 px-3 my-1 font-medium rounded-md cursor-pointer transition-colors group ${active ? "bg-gradient-to-tr from-indigo-200 to-indigo-100 text-indigo-800" : "hover:bg-indigo-50 text-gray-600"}`}
            >
                {icon}
                <span className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}>{text}</span>
                {!expanded && (
                    <div className={`absolute left-full rounded-md px-2 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}>
                        {text}
                    </div>
                )}
            </div>
            {expanded && isOpen && children && (
                <ul className="ml-4 max-width-side-panel">
                    {React.Children.map(children, child => React.cloneElement(child))}
                </ul>
            )}
        </li>
    );
}