
import EventModal from './components/EventModal';
import TicketTypeModal from "./components/TicketTypeModal";
import { Image } from 'primereact/image';

import { getData, deleteData, putData } from './services/api';
import { useSession } from './assets/auth/SessionContext';
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { Calendar } from 'primereact/calendar';
import { Toast } from "primereact/toast";
import { DataTable, DataTableRowToggleEvent, DataTableValueArray } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog } from "primereact/confirmdialog";
import { PlusCircle } from "lucide-react";
import { Tag } from 'primereact/tag';
import React from "react";


const Events = () => {
    const { session } = useSession();

    interface DataItem extends DataTableValueArray {
        id: number;
        ref: string;
        name: string;
        dateFrom: Date;
        image: string;
        ticketTypes: [];
        active: boolean
    }

    const defaultMessage = "Are you sure you want to delete this item?";

    const [dataItems, setDataItems] = useState<DataItem[]>([]);
    const [error, setError] = useState(null);
    const [dateFrom, setDateFrom] = useState<Date>(new Date());
    const [dateTo, setDateTo] = useState<Date>(new Date(dayjs().add(1, "month").valueOf()));
    const [isLoading, setIsLoading] = useState(true);

    const [mode, setMode] = useState("");
    const [Modal, setModal] = useState(<EventModal />);
    const [selectedDataItem, setSelectedDataItem] = useState(null);
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
    const [confirmationDialogMessage, setConfirmationDialogMessage] = useState(defaultMessage)
    const [ticketTypeId, setTicketTypeId] = useState("");
    const [eventId, setEventId] = useState("");
    const [deleteMode, setDeleteMode] = useState("");
    const [currentEventRef, setCurrentEventRef] = useState("");
    const [expandedLevel1Rows, setExpandedLevel1Rows] = useState<any | null>(null);
    const [expandedLevel2Rows, setExpandedLevel2Rows] = useState<any | null>(null);
    const [ticketTypeGroups, setTicketTypeGroups] = useState([]);

    const toast = useRef<Toast>(null);

    const dateFormat = 'dd-mm-yy';

    useEffect(() => {
        getData(session, `ticket-type-groups`, null).then(ticketTypeGroupsData => {
            setTicketTypeGroups(ticketTypeGroupsData);
        });
    }, []);

    useEffect(() => {

        setExpandedLevel1Rows(null);
        setCurrentEventRef("");

        getData(session, `events/ticket-type-group`, `dateFrom=${dayjs(dateFrom).format("YYYY-MM-DD")}&dateTo=${dayjs(dateTo).format("YYYY-MM-DD")}`).then(eventData => {

            for (const event of eventData) {
                event.formatDate = dayjs(event.dateFrom).format("DD-MM-YYYY")
            }

            setDataItems(eventData)
            setIsLoading(false);

        }).catch(err => {
            setError(err);
            setIsLoading(false);
        });

    }, [dateFrom, dateTo, session, setDateFrom, setDateTo]);

    useEffect(() => {
        if (currentEventRef) {
            getData(session, `ticket-types/event/${currentEventRef}`, null).then(ticketTypesData => {
                setDataItems((prevDataItems) =>
                    prevDataItems.map((item) =>
                        item.ref === currentEventRef ? { ...item, ticketTypes: ticketTypesData } : item
                    )
                );
            }).catch(err => {
                setError(err);
            });
        }
    }, [currentEventRef, expandedLevel1Rows, session]);

    async function removeEvent(ref: string) {
        try {
            await deleteData(session, `events/${ref}`, null)

            return { success: true, message: "Event removed" };
        } catch (err: any) {
            return { success: false, message: err.message };
        }
    }

    async function removeTicketType(ref: string) {
        try {
            await deleteData(session, `ticket-types/${ref}`, null)
            return { success: true, message: "Ticket Type removed" };
        } catch (err: any) {
            return { success: false, message: err.message };
        }
    }

    function setDate(fieldName: String, value: Date) {
        if (fieldName === "from" && value !== null) {
            let newDateTo = dateTo;
            newDateTo = dayjs(value).add(1, 'month').toDate();
            setDateFrom(value);
            setDateTo(newDateTo);
        }

        if (fieldName === "to" && value !== null) {
            let newDateFrom = dateFrom;
            newDateFrom = dayjs(value).add(-1, 'month').toDate();
            setDateTo(value);
            setDateFrom(newDateFrom);
        }
    }

    const openModal = (dataItem: any) => {
        setSelectedDataItem(dataItem);
        setVisibleDialog(true);
    };

    const closeModal = async (update: any | null) => {
        setSelectedDataItem(null);
        setVisibleDialog(false);

        if (update && update.message && toast.current) {
            toast.current.show({ severity: 'success', summary: update.message, detail: "" });

            let dataSource = JSON.parse(JSON.stringify(dataItems));

            if (update.newRecord) {

                const newRecord = update.newRecord.data;

                if (update.mode === "ticketType") {

                    const eventIndex = dataSource.findIndex((x: { ref: any; }) => x.ref === newRecord.eventRef);
                    const event = dataSource[eventIndex];

                    if (event && !event.ticketTypes) {
                        event.ticketTypes = [];
                    }

                    event.ticketTypes.push(newRecord);
                } else {
                    if (!newRecord.ticketTypes) {
                        newRecord.ticketTypes = [];
                    }

                    dataSource.push(newRecord)
                }
            } else if (update.updateRecord) {
                const recordToUpdate = update.updateRecord;

                if (update.mode === "ticketType") {
                    const eventIndex = dataSource.findIndex((x: { ref: any; }) => x.ref === recordToUpdate.eventRef);
                    const event = dataSource[eventIndex];

                    const ticketTypeIndex = event.ticketTypes.findIndex((x: { ref: any }) => x.ref === recordToUpdate.ref);

                    if (ticketTypeIndex >= 0) {
                        event.ticketTypes.splice(ticketTypeIndex, 1, recordToUpdate);
                    }
                } else {
                    const item = dataSource.find((x: { ref: any; }) => x.ref === recordToUpdate.ref);

                    if (item) {
                        item.name = recordToUpdate.name;
                        item.active = recordToUpdate.active;
                        item.dateFrom = recordToUpdate.dateFrom;
                    }
                }
            }

            setDataItems(dataSource);
        }
    };

    const confirm = () => {
        setVisibleConfirmDialog(true);
    };

    const onHide = () => {
        setVisibleConfirmDialog(false);
        setConfirmationDialogMessage(defaultMessage);
    };

    const confirmDelete = async () => {

        let removeResult = null;
        let dataSource = JSON.parse(JSON.stringify(dataItems));
        let itemId = "";

        if (deleteMode === "ticketType") {
            itemId = ticketTypeId;
            removeResult = await removeTicketType(itemId) as { success: boolean, message: string };

            const eventIndex = dataSource.findIndex((x: { ref: any; }) => x.ref === currentEventRef);

            const event = dataSource[eventIndex];

            if (event && event.ticketTypes) {

                const ticketTypeIndex = event.ticketTypes.findIndex((x: { ref: any }) => x.ref === itemId);

                event.ticketTypes.splice(ticketTypeIndex, 1);
            }
        } else {
            itemId = eventId;
            removeResult = await removeEvent(itemId) as { success: boolean, message: string }

            const eventIndex = dataSource.findIndex((x: { ref: any; }) => x.ref === eventId);

            if (eventIndex) {
                dataSource.splice(eventIndex, 1);
            }
        }

        if (removeResult && removeResult.success === true && toast.current) {

            setDataItems(dataSource);

            toast.current.show({ severity: 'success', summary: "Removed successfully", detail: "" });
        } else if (removeResult && removeResult.success === false && toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: removeResult.message });
        }
    };

    const eventHeaderTemplate = (data: DataItem) => {
        return (
            <React.Fragment>
                <div className="event-expandable-row">
                    <div className="flex1 first-div">
                        <Image
                            src={data.image}
                            zoomSrc={data.image}
                            alt="Image"
                            width="80"
                            height="60"
                            className=" event-image"
                            preview />
                    </div>
                    <div className="flex1 second-div">
                        <span className="vertical-align-middle ml-2 font-bold line-height-3">{data.name}</span><br />
                        <span className="vertical-align-middle ml-2 font-bold line-height-3">{dayjs(data.dateFrom).format("DD-MM-YYYY")}</span><br />
                        <span className="vertical-align-middle ml-2 font-bold line-height-3">{activeBodyTemplate(data)}</span>
                    </div>
                    <div className='flex1 third-div'>
                        <div className='flex'>
                            <Button className="action-buttons" icon="pi pi-pencil" onClick={() => {
                                setModal(<EventModal />);
                                setMode("update");
                                openModal(data);
                            }} />
                        </div>
                        <div className='flex'>
                            <Button className="action-buttons" icon="pi pi-trash" onClick={() => {
                                setEventId(data.ref);
                                setDeleteMode("event");
                                confirm();
                            }} />
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    };

    const ticketTypeHeaderTemplate = (data: any) => {
        let ticketTypeGroupName = "";

        if (data.ticketTypeGroupRef) {
            const ticketTypeGroup = ticketTypeGroups.find((x: any) => x.ref === data.ticketTypeGroupRef) as any;

            if (ticketTypeGroup) {
                ticketTypeGroupName = ticketTypeGroup.name;
            }
        }

        return (
            <React.Fragment>
                <span className="vertical-align-middle ml-2 font-bold line-height-3">{ticketTypeGroupName}</span><br />
            </React.Fragment>
        );
    };

    const eventFooterTemplate = () => {
        return (
            <React.Fragment>
                <div className="end-of-expandable-row"></div>
            </React.Fragment>
        );
    };

    const renderTag = (active: boolean) => {
        switch (active) {
            case false:
                return 'danger';

            case true:
                return 'success';

        }
    };

    const handleRowReorder = async (e: any) => {
        try {

            const reorderedItems = e.value as [{ ticketTypeGroupRef: string, levelOrder: number }];

            let currentGroupRef = "";
            let i = 1;
            for (const item of reorderedItems) {
                if (currentGroupRef !== item.ticketTypeGroupRef) {
                    i = 1;
                    currentGroupRef = item.ticketTypeGroupRef
                }

                item.levelOrder = i;
                i++;
            }

            let dataSource = JSON.parse(JSON.stringify(dataItems));

            const event = dataSource.find((x: { ref: string; }) => x.ref === currentEventRef);

            if (event) {
                event.ticketTypes = reorderedItems;

                await putData(session, `ticket-types`, reorderedItems, null)
                setDataItems(dataSource);
            } else {
                throw new Error("Event not found")
            }

            return { success: true, message: "Ticket type order updated" };
        } catch (err: any) {
            return { success: false, message: err.message };
        }
    };

    const activeBodyTemplate = (data: any) => {
        return <Tag className="active-tag" value={data.active ? "active" : "inactive"} severity={renderTag(data.active)} />
    }

    const renderTicketTypeGroupDetails = (rowData: any) => {

        const ticketTypes = rowData.ticketTypes || [];

        const preprocessedData = ticketTypes.sort((a: any, b: any) => {
            const compareGroup = a.ticketTypeGroupRef.localeCompare(b.ticketTypeGroupRef);

            // If ticketTypeGroupRef is the same, compare by levelOrder
            return compareGroup === 0 ? a.levelOrder - b.levelOrder : compareGroup;
        }).map((row: { ticketTypeGroupRef: any }) => ({
            ...row,
            groupField: `${row.ticketTypeGroupRef}|${currentEventRef}` // Concatenate the values of groupField1 and groupField2
        }));

        return (
            <div>
                <div className="main-table-level-1">
                    <DataTable
                        value={preprocessedData}

                        rowGroupMode="subheader"
                        groupRowsBy="groupField"
                        expandableRowGroups
                        expandedRows={expandedLevel2Rows}
                        onRowToggle={(e: DataTableRowToggleEvent) => { setExpandedLevel2Rows(e.data as any) }}

                        reorderableColumns
                        reorderableRows
                        onRowReorder={handleRowReorder}

                        stripedRows={true}
                        dataKey="ref"
                        emptyMessage="No records found."
                        tableStyle={{ minWidth: '50rem' }}
                        rowGroupHeaderTemplate={ticketTypeHeaderTemplate}
                    >

                        <Column rowReorder style={{ width: '3rem' }} />
                        {/* <Column field="levelOrder" header="Level" style={{ minWidth: '12rem' }}></Column> */}
                        <Column field="name" header="Name" style={{ minWidth: '12rem' }}></Column>
                        <Column field="price" header="Price" style={{ minWidth: '12rem' }}></Column>
                        <Column field="active" header="Active" style={{ minWidth: '12rem' }} body={activeBodyTemplate}></Column>
                        <Column key="action" header="Actions" headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }} body={(ticketTypeRowData) => {
                            return (
                                <div className='flex'>
                                    <div className='flex'>
                                        <Button className="action-buttons" icon="pi pi-pencil" onClick={() => {
                                            setModal(<TicketTypeModal />);
                                            setMode("update");
                                            openModal({ ...ticketTypeRowData, eventRef: rowData.ref, eventName: rowData.name, ticketTypeGroups: ticketTypeGroups });
                                        }} />
                                    </div>
                                    <div className='flex'>
                                        <Button className="action-buttons" icon="pi pi-trash" onClick={() => {
                                            setDeleteMode("ticketType");
                                            setTicketTypeId(ticketTypeRowData.ref);
                                            confirm();
                                        }} />
                                    </div>
                                </div>
                            );
                        }
                        } />

                    </DataTable>
                </div >
                <div className='add-container flex-start'>
                    <Button className="add-button" type="button" tooltip="Add Ticket Type" onClick={() => { setModal(<TicketTypeModal />); setMode("create"); openModal({ eventRef: rowData.ref, eventName: rowData.name, ticketTypeGroups: ticketTypeGroups }) }} icon={<PlusCircle size={80} />}>
                    </Button>
                </div>
            </div>
        );
    };

    const onRowToggleLevel1 = (e: DataTableRowToggleEvent) => {
        const eventRef1 = Object.keys(e.data)[0]; // Get the reference of the clicked row
        const eventRef2 = Object.keys(e.data)[1]; // Get the reference of the clicked row

        const newEventRef = eventRef2 || eventRef1;

        // Collapse the currently expanded row if it is already expanded
        if (expandedLevel1Rows && expandedLevel1Rows[newEventRef]) {
            setExpandedLevel1Rows({});
            setCurrentEventRef("");
        } else {
            // Expand the clicked row and collapse others
            setExpandedLevel1Rows({ [newEventRef]: true });
            setCurrentEventRef(newEventRef);
        }
    };

    if (isLoading || (!dataItems && !error)) {
        // Render loading indicator or placeholder while data is being fetched
        return (
            <div className="flex">
                <div className="table-container">

                </div>
            </div>
        )
    }

    return (
        <div className="flex">
            <div className="table-container">
                <div className="card flex flex-wrap gap-3 p-fluid select-dates">
                    <div className="flex1">
                        <label htmlFor="buttondisplay" className="font-bold block mb-2">
                            From
                        </label>
                        <Calendar dateFormat={dateFormat} className="calendar" value={dateFrom} onChange={(e) => setDate("from", e.value || new Date())} showIcon />
                    </div>
                    <div className="flex1">
                        <label htmlFor="buttondisplay" className="font-bold block mb-2">
                            To
                        </label>

                        <Calendar value={dateTo} dateFormat={dateFormat} className="calendar" onChange={(e) => setDate("to", e.value || new Date())} showIcon />
                    </div>
                </div>

                <div>
                    <div>
                        <Toast ref={toast} />
                        <div className="main-table-level-0">

                            <DataTable
                                value={dataItems}

                                rowGroupMode="subheader"
                                groupRowsBy="ref"
                                expandableRowGroups
                                expandedRows={expandedLevel1Rows}
                                onRowToggle={onRowToggleLevel1}
                                rowGroupHeaderTemplate={eventHeaderTemplate}
                                rowGroupFooterTemplate={eventFooterTemplate}

                                paginator
                                rows={10}
                                dataKey="ref"
                                emptyMessage="No records found."
                                tableStyle={{ minWidth: '50rem' }}>

                                <Column
                                    body={renderTicketTypeGroupDetails}
                                    expander
                                    style={{ width: '5em', textAlign: 'center' }}
                                />

                            </DataTable>

                            <Dialog visible={visibleDialog} onHide={() => { closeModal(null) }}>
                                <div>
                                    {selectedDataItem && (
                                        <div>
                                            {React.cloneElement(Modal, { selectedDataItem, closeModal, mode })}
                                        </div>
                                    )}
                                </div>
                            </Dialog>

                            <ConfirmDialog
                                visible={visibleConfirmDialog}
                                onHide={onHide}
                                message={confirmationDialogMessage}
                                header="Confirmation"
                                icon="pi pi-exclamation-triangle"
                                accept={confirmDelete}
                                reject={onHide}
                                acceptClassName="confirm-dialog-yes"
                                rejectClassName="confirm-dialog-no"
                            />
                        </div>
                        <div className='add-container'>
                            <Button className="add-button" type="button" tooltip="Add Event" onClick={() => { setModal(<EventModal />); setMode("create"); openModal({}) }} icon={<PlusCircle size={80} />}>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Events; 