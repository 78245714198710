import { useEffect, useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import Table from "./components/Table";

import { getData } from "./services/api";
import { useSession } from "./assets/auth/SessionContext";
import models from "./utils/models";

const CashOuts = () => {

    const toast = useRef<Toast>(null);

    const [selectedDeviceUser, setSelectedDeviceUser] = useState(null);
    const [deviceUsers, setDeviceUsers] = useState([]);
    const [type] = useState("cashOuts")

    const [data, setData] = useState([]);

    const { session } = useSession();

    useEffect(() => {
        getData(session, `device-users`, "dropdown=true").then(deviceUsers => {
            const eventsForMultiSelect = deviceUsers.map((x: { displayName: any; ref: any }) => ({
                name: x.displayName,
                value: x.ref
            }));

            setDeviceUsers(eventsForMultiSelect);
        }).catch(err => {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
            }
        });
    }, []);


    const handleSelectedDeviceUser = (data: any) => {
        try {
            setSelectedDeviceUser(data);
            getData(session, `reports/list-cash-outs/${data}`, null).then(reportData => {
                setData(reportData || []);
                return;
            }).catch(err => {
                if (toast.current) {
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                }
            });
        } catch (err: any) {
            if (toast.current && err) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
            }
        }
    }

    return (
        <div className="flex">
            <Toast ref={toast} />
            <div className="table-container">
                <div className="card flex flex-wrap gap-3 p-fluid select-dates">
                    <div className="flex1 top-bar-filtering">
                        <label htmlFor="text-label" className="font-bold block mb-2">Select Seller</label>
                        <Dropdown
                            onChange={(e) => { handleSelectedDeviceUser(e.value); }}
                            options={deviceUsers}
                            optionLabel="name"
                            placeholder="Select Seller"
                            className="dropdown"
                            value={selectedDeviceUser}
                        />
                    </div>
                </div>

                <div>
                    <div className="table-container">
                        <Table
                            data={data}
                            type={type}
                            editButtonVisible={false}
                            addButtonVisible={false}
                            removeButtonVisible={false}
                            actionsVisible={false}
                            exportButtonVisible={true}
                            map={models.cashOutMap()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CashOuts;
