import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

import { postData, getData, putData } from '../services/api';
import { useSession } from '../assets/auth/SessionContext';

import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from "primereact/multiselect";

export default function UserModal(modalData: any) {

    const [formData, setFormData] = useState(modalData.selectedDataItem);
    const [usernameEditable, setUsernameEditable] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [modalHeader, setModalHeader] = useState("");

    const [roles, setRoles] = useState([]);
    const [teams, setTeams] = useState([]);

    const toast = useRef<Toast>(null);

    const { session } = useSession();

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (modalData.mode === "create") {
            setUsernameEditable(true)
            setModalHeader("Creating user")
        } else {
            setUsernameEditable(false)
            setModalHeader("Updating user")
        }

        getData(session, `teams`, null).then(teams => {

            const reamsForMultiSelect = teams.map((x: { name: any; ref: any }) => ({
                name: x.name,
                value: x.ref
            }));

            setTeams(reamsForMultiSelect)

            getData(session, `roles`, "isUi=true").then(roles => {

                const rolesForDropdown = roles.map((x: { name: any; ref: any; }) => ({
                    name: x.name,
                    value: x.ref
                }));

                setRoles(rolesForDropdown);
            });
        });
    }, []);

    useEffect(() => {
        if (roles && roles.length > 0) {
            setSelectedRole(formData.role);
        }
    }, [formData.role, roles]);

    useEffect(() => {
        if (teams && teams.length > 0) {
            setSelectedTeams(formData.teams);
        }
    }, [formData.teams, setSelectedTeams, teams]);

    function save(data: any) {

        try {

            if (!data.username) {
                throw new Error("Username is required")
            }

            if (!data.firstName || !data.lastName) {
                throw new Error("Both first and last name are required")
            }

            if (!data.email) {
                throw new Error("E-Mail is required")
            } else {
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                if (!regex.test(data.email)) {
                    throw new Error("E-Mail address not valid")
                }
            }

            if (modalData.mode === "create") {
                if (!data.password) {
                    throw new Error("Password is required")
                }
            }

            if (data.password) {
                if (data.password !== data.confirmPassword) {
                    throw new Error("Passwords do not match")
                }
            }

            if (!selectedRole) {
                throw new Error("Role is required")
            }

            if (!selectedTeams || (selectedTeams && selectedTeams.length <= 0)) {
                throw new Error("At least 1 team must be selected")
            }

            let user = {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                username: data.username,
                ref: data.username,
                teams: selectedTeams,
                role: selectedRole
            } as any;

            if (data.password) {
                user = { ...user, password: data.password };
            }

            if (modalData.mode === "update") {
                putData(session, `client-users/${user.username}`, user, null).then(updateUserData => {

                    user.displayName = `${user.firstName} ${user.lastName}`;

                    modalData.closeModal({ toast: true, message: `${user.displayName} updated`, updateRecord: user });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'User created', detail: "" });
                    }

                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }
                });
            } else if (modalData.mode === "create") {

                postData(session, `client-users`, user, null).then(newUserData => {

                    user.displayName = `${user.firstName} ${user.lastName}`;

                    modalData.closeModal({ toast: true, message: `${user.displayName} created`, newRecord: newUserData });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'User created', detail: "" });
                    }

                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }
                });
            }
        } catch (error: any) {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: error.message });
            }
        }
    }

    return (

        <div className="modal-container">

            <Toast ref={toast} />
            <div className="modal ">
                <div className="modal-header">
                    <span className="modal-title">{modalHeader}</span>
                    <span className="modal-close" onClick={modalData.closeModal}>&times;</span>
                </div>
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="username">Username</label>
                        <input
                            className="text-input"
                            readOnly={!usernameEditable}
                            type="text"
                            id="username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="firstName">First Name</label>
                        <input
                            className="text-input"
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="lastName">Last Name</label>
                        <input
                            className="text-input"
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="email">Email</label>
                        <input
                            className="text-input"
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="password">Password</label>
                        <input
                            className="text-input"
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="confirmPassword">Confirm Password</label>
                        <input
                            className="text-input"
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                        />
                    </div>
                </div>


                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="role">Role</label>
                        <Dropdown
                            onChange={(e) => { setSelectedRole(e.value); }}
                            options={roles}
                            optionLabel="name"
                            placeholder="Select role"
                            className="dropdown"
                            value={selectedRole} />

                    </div>

                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="teams">Teams</label>
                        <MultiSelect value={selectedTeams} onChange={(e) => setSelectedTeams(e.value)} options={teams} optionLabel="name"
                            filter placeholder="Select Teams" className="dropdown" />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">

                    </div>
                </div>

                <div className="footer">
                    <button className="save-button" type="button" onClick={() => {
                        save(formData);
                    }
                    }>Save</button>
                    <button type="button" className="cancel-button" onClick={modalData.closeModal}>Cancel</button>
                </div>
            </div>
        </div>
    )
}