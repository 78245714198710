import React, { FC } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, SxProps } from '@mui/material';
import { Dayjs } from 'dayjs';

// Define the props interface
interface CustomDateTimePickerProps {
    id: string;
    value: Dayjs | null;
    onChange: (id: string, newValue: Dayjs | null) => void;
}

// Define the component as a functional component
const CustomDateTimePicker: FC<CustomDateTimePickerProps> = ({ id, value, onChange }) => {
    return React.createElement(
        LocalizationProvider,
        { dateAdapter: AdapterDayjs },
        React.createElement(
            Box,
            { sx: { display: 'flex', flexDirection: 'column', gap: 2 } },
            React.createElement(DateTimePicker, {
                value,
                onChange: (newValue) => onChange(id, newValue),
                format: 'DD-MM-YYYY HH:mm',
                ampm: false,
                slotProps: {
                    textField: {
                        fullWidth: true,
                        sx: {
                            '& .MuiOutlinedInput-root': {
                                height: '40px',
                                width: '250px',
                                '& fieldset': {
                                    borderColor: '#ccc',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#ccc',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#ccc',
                                },
                                '& .MuiSvgIcon-root': {
                                    color: '#007bff',
                                },
                            },
                        } as SxProps, // Explicitly typing this as SxProps to ensure compatibility
                    },
                },
            })
        )
    );
};

export default CustomDateTimePicker;
