import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

interface TicketData {
    _id: string;
    name: string;
    price: number;
    ticketCapacity: number;
    status: string;
    tracking?: {
        ticketsSold: number;
    };
    isComplimentary?: boolean;
}

interface TicketBarGraphProps {
    data: TicketData[];
}

const TicketBarGraph: React.FC<TicketBarGraphProps> = ({ data }) => {
    const formattedData = data.map((item) => ({
        name: item.name,
        sold: item.tracking?.ticketsSold || 0,
        capacity: item.ticketCapacity,
        complimentary: item.isComplimentary,
        price: item.price,
        status: item.status,
    }));

    const generateHSLColor = (i: number, total: number) => {
        const hue = (i * 360) / total;
        return `hsl(${hue}, 70%, 50%)`;
    };

    const colors = formattedData.map((_, i) => generateHSLColor(i, formattedData.length));

    const chartData = {
        labels: formattedData.map((item) => item.name),
        datasets: [
            {
                label: 'Tickets Sold',
                data: formattedData.map((item) => item.sold),
                backgroundColor: colors,
            },
            {
                label: 'Remaining Capacity',
                data: formattedData.map((item) => item.capacity - item.sold),
                backgroundColor: colors.map(color => color.replace('50%', '80%')),
            },
        ],
    };

    const options = {
        indexAxis: 'y' as const,
        scales: {
            x: {
                stacked: true,
                beginAtZero: true,
                ticks: {
                    callback: function (tickValue: any, index: number, ticks: any[]) {
                        if (Number.isInteger(tickValue)) {
                            return tickValue.toString();
                        } else {
                            return '';
                        }
                    },
                },
            },
            y: {
                stacked: true,
                ticks: {
                    font: {
                        size: 12,
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const totalCapacity = Math.round(formattedData[context.dataIndex].capacity);
                        const ticketsSold = Math.round(formattedData[context.dataIndex].sold);
                        return [
                            `Total Capacity: ${totalCapacity}`,
                            `Tickets Sold: ${ticketsSold}`,
                            `Remaining: ${totalCapacity - ticketsSold}`
                        ];
                    },
                },
            },
            legend: {
                display: false,
            },
        },
        maintainAspectRatio: false,
        barThickness: 30,
    };

    const containerHeight = Math.max(150, formattedData.length * 50); // Ensure a minimum height to avoid issues with small datasets

    return (
        <div style={{ display: 'flex', height: containerHeight }}>
            <div style={{ flex: 1 }}>
                <Bar data={chartData} options={options} />
            </div>
            <div style={{ flex: 0.3, padding: '10px' }}>
                {formattedData.map((item, index) => (
                    <div key={index} style={{ marginBottom: '10px' }}>
                        <div style={{ backgroundColor: colors[index], height: '10px', width: '10px', display: 'inline-block', marginRight: '5px' }}></div>
                        <p style={{ display: 'inline' }}>
                            {item.name} - €{item.price} - {item.status} - {item.sold}/{item.capacity}
                            {item.complimentary && ' - [Complimentary]'}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TicketBarGraph;
