import React from 'react';

interface TicketDistributionChartProps {
    totalTicketsSold: number;
    capacity: number;
    totalRevenue: number
}

const TicketDistributionChart: React.FC<TicketDistributionChartProps> = ({ totalTicketsSold, capacity, totalRevenue }) => {
    const remaining = capacity - totalTicketsSold; // Calculate remaining capacity

    // Define colors for each segment
    const colors = {
        ticketsSold: '#017BFF', // Color for total tickets sold
        remaining: '#d3d3d3' // Color for remaining capacity
    };

    const radius = 80;
    const strokeWidth = 40;
    const circumference = 2 * Math.PI * radius;
    const strokeDasharray = `${circumference} ${circumference}`;
    const rotationOffset = -90;

    // Calculate the rotation offset for each segment
    const ticketsSoldOffset = rotationOffset;
    const remainingOffset = rotationOffset + (360 * (totalTicketsSold / capacity));

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Chart */}
            <svg width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                {/* Remaining Capacity Segment */}
                <circle
                    cx="100"
                    cy="100"
                    r={radius}
                    stroke={colors.remaining}
                    strokeWidth={strokeWidth}
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={circumference * (1 - (remaining / capacity))}
                    fill="none"
                    transform={`rotate(${remainingOffset} 100 100)`}
                />
                {/* Total Tickets Sold Segment */}
                <circle
                    cx="100"
                    cy="100"
                    r={radius}
                    stroke={colors.ticketsSold}
                    strokeWidth={strokeWidth}
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={circumference * (1 - (totalTicketsSold / capacity))}
                    fill="none"
                    transform={`rotate(${ticketsSoldOffset} 100 100)`}
                />
                {/* Center Text */}
                <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="16" fill="#000">
                    {totalTicketsSold > 0 ? `${totalTicketsSold}/${capacity}` : 'No Data'}
                </text>
            </svg>

            {/* Legend */}
            <div style={{ marginLeft: '20px' }}>
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: colors.ticketsSold, marginRight: '8px' }}></span>
                    Tickets Sold: {totalTicketsSold}
                </div>
                <div>
                    <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: colors.remaining, marginRight: '8px' }}></span>
                    Remaining: {remaining}
                </div>
                <div style={{ marginTop: '20px' }}>
                    <span>Total revenue: €{totalRevenue}</span>
                </div>
            </div>
        </div>
    );
};

export default TicketDistributionChart;
