// api.ts

import axios from 'axios';

import { config } from "../config/config"

// Define a function to make a POST request
export async function postData(context: any, url: string, body: any, queryString: string | null): Promise<any> {
    try {

        const api = prepareApiCall(context, url, queryString, body)

        let result = await axios.post(api.url, api.data, { headers: api.headers }).catch((error) => {
            handleErrors(error);
        });

        let data = result ? result.data : []
        return data
    } catch (error) {
        throw error;
    }
}

export async function putData(context: any, url: string, body: any, queryString: string | null): Promise<any> {
    try {

        const api = prepareApiCall(context, url, queryString, body)

        let result = await axios.put(api.url, api.data, { headers: api.headers }).catch((error) => {
            handleErrors(error);
        });

        let data = result ? result.data : []
        return data
    } catch (error) {
        throw error;
    }
}

export async function getData(context: any, url: string, queryString: string | null): Promise<any> {
    try {

        const api = prepareApiCall(context, url, queryString, null)

        let result = await axios.get(api.url, { headers: api.headers }).catch((error) => {
            handleErrors(error);
        });

        let data = result ? (result.data ? (result.data.data ? result.data.data : result.data) : result.data) : []

        return data
    } catch (error) {
        throw error;
    }
}

export async function deleteData(context: any, url: string, queryString: string | null): Promise<any> {
    try {

        const api = prepareApiCall(context, url, queryString, null)

        let result = await axios.delete(api.url, { headers: api.headers }).catch((error) => {
            handleErrors(error);
        });

        let data = result ? (result.data ? (result.data.data ? result.data.data : result.data) : result.data) : []

        return data
    } catch (error) {
        throw error;
    }
}

function prepareApiCall(context: any, url: string, queryString: string | null, body: any) {

    if (!queryString) {
        queryString = ""
    }

    let tokenData = null;

    if (context) {
        const { token } = context;
        if (token) {
            tokenData = token;
        }
    }

    return {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": !queryString.includes('login/') && tokenData ? `Bearer ${tokenData.accessToken}` : undefined
        },
        url: config.apiAddress + url + (queryString ? `?` + queryString : ""),
        data: body
    }
}

function handleErrors(error: any) {
    if (error.response && error.response.data && error.response.data.message === 'jwt expired') {
        window.location.href =
            '/?reqUrl=' + window.location.pathname + '&msg=Session Expired.Please login again.'
    }

    throw error.response.data
}
