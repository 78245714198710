import { Home, Users, SmartphoneCharging, CalendarSearch, Layers, Flag, Calendar, LifeBuoy, Settings, ShieldHalf, ShieldCheck, LogOut, Ticket, Menu } from "lucide-react";
import Sidebar, { SidebarItem } from "./../components/Sidebar"
import { useSession } from './../assets/auth/SessionContext';
import { useState } from "react";
import { useEffect } from "react";

function MainLayout({ setExpandedState }) {

    const route = window.location.pathname.replace("/", "");
    const { session } = useSession();

    const userRole = session?.user?.role;

    const [expandedItems, setExpandedItems] = useState({});
    const [expanded, setExpanded] = useState(true);

    const handleToggle = (id) => {
        setExpandedItems((prev) => {
            // Create a new object where all items are set to false
            const newExpandedItems = Object.keys(prev).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {});

            // If an id is provided, set the clicked item to the opposite of its current value
            if (id !== null) {
                newExpandedItems[id] = !prev[id];
            }

            return newExpandedItems;
        });
    };

    // Inside your MainLayout function
    useEffect(() => {
        // Retrieve expanded items from local storage on mount
        const savedExpandedItems = localStorage.getItem("expandedItems");
        if (savedExpandedItems) {
            setExpandedItems(JSON.parse(savedExpandedItems));
        }
    }, []);

    useEffect(() => {
        // Save expanded items to local storage whenever it changes
        localStorage.setItem("expandedItems", JSON.stringify(expandedItems));
    }, [expandedItems]);

    useEffect(() => {
        setExpandedState(expanded);
    }, [expanded, setExpandedState]);

    return (
        <div className="flex">
            <Sidebar expanded={expanded} setExpanded={setExpanded}>
                <SidebarItem icon={<Home size={20} />} path="/home" text="Home" active={route === "home" ? true : false} />
                <SidebarItem icon={<Users size={20} />} path="/users" text="Users" active={route === "users" ? true : false} />
                <SidebarItem icon={<SmartphoneCharging size={20} />} path="/device-users" text="Sellers" active={route === "device-users" ? true : false} />
                <SidebarItem icon={<CalendarSearch size={20} />} path="/events" text="Events" active={route === "events" ? true : false} />
                <SidebarItem icon={<Layers size={20} />} path="/event-categories" text="Event Categories" active={route === "event-categories" ? true : false} />
                <SidebarItem icon={<Calendar size={20} />} path="/ticket-type-groups" text="Ticket Type Groups" active={route === "ticket-type-groups" ? true : false} />
                <SidebarItem icon={<ShieldCheck size={20} />} path="/roles" text="Roles" active={route === "roles" ? true : false} />
                <SidebarItem icon={<ShieldHalf size={20} />} path="/teams" text="Teams" active={route === "teams" ? true : false} />
                <SidebarItem icon={<Ticket size={20} />} text="Ticket Management" active={route === "ticket" ? true : false} isOpen={expandedItems["tickets"]} id="tickets" onToggle={handleToggle}>
                    <SidebarItem icon={<Menu size={20} />} path="/create-ticket" text="Create Ticket" active={route === "create-ticket"} />
                    <SidebarItem icon={<Menu size={20} />} path="/ticket-management" text="List Tickets" active={route === "ticket-management"} />
                </SidebarItem>
                <hr className="my-3" />
                {userRole === "superuser" && (
                    <><SidebarItem icon={<Settings size={20} />} path="/permissions" text="Permissions" active={route === "permissions" ? true : false} /><SidebarItem icon={<LifeBuoy size={20} />} path="/route-resources" text="Route Resources" active={route === "route-resources" ? true : false} /></>
                )}
                <SidebarItem icon={<Flag size={20} />} text="Reporting" active={route === "reporting" ? true : false} isOpen={expandedItems["reporting"]} id="reporting" onToggle={handleToggle}>
                    <SidebarItem icon={<Menu size={20} />} path="/ticket-sales" text="Ticket Sales" active={route === "ticket-sales"} />
                    <SidebarItem icon={<Menu size={20} />} path="/sellers-progress" text="Sellers progress" active={route === "sellers-progress"} />
                    <SidebarItem icon={<Menu size={20} />} path="/cash-outs" text="Cash Outs" active={route === "cash-outs"} />
                </SidebarItem>
                <hr className="my-3" />
                <SidebarItem icon={<LogOut size={20} />} action="logout" text="Logout" />
            </Sidebar>
        </div>
    )
}

export default MainLayout