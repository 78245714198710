const dayjs = require('dayjs');

module.exports = {
    max1MonthCustom: function (options) {
        let { fieldName, from, to, value, setTo, setFrom } = options;

        if (!dayjs(value).isValid()) {
            return;
        }

        const year = dayjs(value).year();

        if (year < 2010 || year > 2100) {
            return;
        }

        if (fieldName === "from" && value !== null) {
            setFrom(value);

            if (dayjs(to).isBefore(dayjs(value).add(-1, 'month'))) {
                setTo(dayjs(value).add(-1, 'month'));
            }

            if (dayjs(to).isAfter(dayjs(value).add(1, 'month'))) {
                setTo(dayjs(value).add(1, 'month'));
            }
        }

        if (fieldName === "to" && value !== null) {
            setTo(value);

            if (dayjs(from).isAfter(dayjs(value).add(1, 'month'))) {
                setFrom(dayjs(value).add(1, 'month'));
            }

            if (dayjs(from).isBefore(dayjs(value).add(-1, 'month'))) {
                setFrom(dayjs(value).add(-1, 'month'));
            }
        }

        return;
    },
    max1Month: function (options) {
        let { fieldName, from, to, value, setTo, setFrom } = options;

        if (!dayjs(value).isValid()) {
            return;
        }

        const year = dayjs(value).year();

        if (year < 2010 || year > 2100) {
            return;
        }

        if (fieldName === "from" && value !== null) {
            setFrom(value);

            // Ensure "to" date is not more than one month before "from"
            if (dayjs(to).isBefore(dayjs(value).add(-1, 'month'))) {
                setTo(dayjs(value).add(-1, 'month').toDate());
            }

            // Ensure "to" date is not more than one month after "from"
            if (dayjs(to).isAfter(dayjs(value).add(1, 'month'))) {
                setTo(dayjs(value).add(1, 'month').toDate());
            }
        }

        if (fieldName === "to" && value !== null) {
            setTo(value);

            // Ensure "from" date is not more than one month after "to"
            if (dayjs(from).isAfter(dayjs(value).add(1, 'month'))) {
                setFrom(dayjs(value).add(1, 'month').toDate());
            }

            // Ensure "from" date is not more than one month before "to"
            if (dayjs(from).isBefore(dayjs(value).add(-1, 'month'))) {
                setFrom(dayjs(value).add(-1, 'month').toDate());
            }
        }
    }
}