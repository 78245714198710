import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import utils from "../utils/data-tables";
import { Button, ButtonProps } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FunctionComponentElement, useEffect, useRef, useState } from 'react';
import React from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

import { PlusCircle } from "lucide-react";
import * as XLSX from 'xlsx';
import dataTables from '../utils/data-tables';

export default function Table(data: any) {

    const [dataItems, setDataItems] = useState<any[]>([]);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    });
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);

    const [additionalData, setAdditionalData] = useState(null);
    const [selectedDataItem, setSelectedDataItem] = useState(null);
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
    const [selectedRowRef, setSelectedRowRef] = useState<{ ref: String } | null>(null);
    const [Modal, setModal] = useState(data.modal);
    const [itemId, setItemId] = useState(null);
    const [mode, setMode] = useState("");

    const toast = useRef<Toast>(null);

    const type = data.type;
    const customButtons = data.customButtons || [];
    const customModals = data.customModals || [];
    const editButtonVisible = data.editButtonVisible === false ? false : true;
    const removeButtonVisible = data.removeButtonVisible === false ? false : true;
    const addButtonVisible = data.addButtonVisible === false ? false : true;
    const actionsVisible = data.actionsVisible === false ? false : true;
    const exportButtonVisible = data.exportButtonVisible === true ? true : false;
    const showFilters = data.showFilters === false ? false : true;

    useEffect(() => {
        if (data.lazy) {
            loadData(lazyParams);
        } else {
            if (data.data) {
                setDataItems(data.data);
            } else {
                setDataItems([]);
            }
        }
    }, [lazyParams, data.data]);

    useEffect(() => {
        setAdditionalData(data.additionalData);

        if (data.error && data.error.message) {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: data.error.message });
            }
        }
    }, [data.additionalData, data.error]);

    const loadData = async (lazyParams: any) => {
        setLoading(true);

        // Replace this with your API call to fetch data
        const result = await fetchItems(lazyParams);

        setDataItems(result.items);
        setTotalRecords(result.totalRecords);
        setLoading(false);
    };

    const fetchItems = async (lazyParams: any) => {
        const { first, rows } = lazyParams;

        // Simulate a delay and fetch data; replace this with actual data fetching logic
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const allData = [
            // Simulate your full dataset here
            { ref: '1', name: 'John', age: 30, job: 'Developer' },
            { ref: '2', name: 'Jane', age: 25, job: 'Designer' },
            // Add more data as needed...
        ];

        const slicedData = allData.slice(first, first + rows);

        return {
            items: slicedData,
            totalRecords: allData.length,
        };
    };

    const onPage = (event: any) => {
        setLazyParams(event);
    };

    const columns = utils.format(type) || [];

    const openModal = (dataItem: any) => {
        setSelectedDataItem(dataItem);
        setVisibleDialog(true);
    };

    const closeModal = async (update: any | null) => {
        setSelectedDataItem(null);
        setVisibleDialog(false);

        if (update && update.message && toast.current) {
            toast.current.show({ severity: 'success', summary: update.message, detail: "" });

            let newDataItems = JSON.parse(JSON.stringify(dataItems));

            if (update.newRecord) {
                const newRecord = update.newRecord;
                newDataItems.push(newRecord.data);
            } else if (update.updateRecord) {
                const recordToUpdate = update.updateRecord;
                const index = newDataItems.findIndex((x: { ref: any; }) => x.ref === recordToUpdate.ref);

                if (index >= 0) {
                    newDataItems.splice(index, 1, recordToUpdate);
                }
            }

            setDataItems(newDataItems);
        }
    };

    const confirm = () => {
        setVisibleConfirmDialog(true);
    };

    const onHide = () => {
        setVisibleConfirmDialog(false);
    };

    const confirmDelete = async () => {
        setVisibleConfirmDialog(false);
        const removeResult = await data.remove(itemId); // this is inherited

        if (removeResult.success === true && toast.current) {
            const index = dataItems.findIndex(x => x.ref === itemId);
            const newDataItems = JSON.parse(JSON.stringify(dataItems));
            newDataItems.splice(index, 1);
            setDataItems(newDataItems);

            toast.current.show({ severity: 'success', summary: "Removed successfully", detail: "" });
        } else if (removeResult.success === false && toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: removeResult.message });
        }
    };

    const exportToExcel = () => {

        if (!data.map && toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: "No mapping found!" });
            return;
        }

        if (dataItems.length === 0 && toast.current) {
            toast.current.show({ severity: 'error', summary: 'Error Message', detail: "Nothing to export" });
            return;
        }

        const remap = dataTables.remappedDataItems(dataItems, data.map);

        const worksheet = XLSX.utils.json_to_sheet(remap);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "data.xlsx");
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="main-table">
                <div className="table-header">
                    {exportButtonVisible && (
                        <Button className="save-button" onClick={exportToExcel} label="Export to Excel" />
                    )}
                </div>
                <DataTable
                    value={dataItems}
                    stripedRows={true}
                    paginator
                    rows={lazyParams.rows}
                    first={lazyParams.first}
                    totalRecords={totalRecords}
                    lazy={data.lazy}
                    loading={loading}
                    dataKey="ref"
                    filterDisplay="row"
                    emptyMessage="No records found."
                    tableStyle={{ minWidth: '50rem' }}
                    onPage={data.lazy ? onPage : undefined}
                    rowClassName={(rowData) => ({
                        'selected-row': selectedRowRef === rowData.ref,
                    })}
                >
                    {
                        columns.map(col => (
                            col.type ?
                                <Column key={col.field} body={data.customRowBodyTemplate} field={col.field} header={col.header} />
                                : <Column key={col.field} field={col.field} header={col.header} filter={showFilters} filterPlaceholder="Search" />
                        ))
                    }
                    {actionsVisible && (
                        <Column key="action" header="Actions" headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }} body={(rowData) => {
                            return (
                                <div className='flex'>
                                    {editButtonVisible && (
                                        <div className='flex' >
                                            <Button className="action-buttons" icon="pi pi-pencil" onClick={() => {
                                                setModal(data.modal);
                                                setMode("update");
                                                openModal(rowData);
                                            }} />
                                        </div>
                                    )}
                                    <div className='flex'>
                                        {removeButtonVisible && (
                                            <Button className="action-buttons" icon="pi pi-trash" onClick={() => {
                                                setItemId(rowData.ref);
                                                confirm();
                                            }} />
                                        )}
                                    </div>
                                    {
                                        customButtons && (customButtons.map((button: {
                                            id: string;
                                            visibleDialog: boolean | undefined;
                                            selectableRow: boolean | undefined;
                                            customModal: FunctionComponentElement<{ selectedDataItem: never; }>; style: Object, icon: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | ((options: { [key: string]: any; iconProps: React.HTMLProps<any> | React.SVGProps<any>; element: React.ReactNode; props?: ButtonProps | undefined; }) => React.ReactNode) | null | undefined; onClick: (arg0: any) => void;
                                        }, index: React.Key | null | undefined) => (
                                            <div className='flex' key={index}>
                                                <Button className="action-buttons" icon={button.icon}
                                                    onClick={() => {
                                                        button.onClick(rowData);

                                                        if (button.selectableRow) {
                                                            setSelectedRowRef(rowData.ref);
                                                        }
                                                    }}
                                                    disabled={data.shouldDisableButton ? data.shouldDisableButton(rowData, button.id) : false}
                                                    visible={data.shouldRemoveButton ? data.shouldRemoveButton(rowData, button.id) : false}
                                                    style={button.style} />
                                            </div>
                                        )))
                                    }
                                </div>
                            );
                        }} />
                    )}
                </DataTable>

                <Dialog visible={visibleDialog} onHide={() => { closeModal(null); }}>
                    <div>
                        {selectedDataItem && (
                            <div>
                                {React.cloneElement(Modal, { selectedDataItem, closeModal, mode, additionalData })}
                            </div>
                        )}
                    </div>
                </Dialog>

                <ConfirmDialog
                    visible={visibleConfirmDialog}
                    onHide={onHide}
                    message="Are you sure you want to delete this item?"
                    header="Confirmation"
                    icon="pi pi-exclamation-triangle"
                    accept={confirmDelete}
                    reject={onHide}
                    acceptClassName="confirm-dialog-yes"
                    rejectClassName="confirm-dialog-no"
                />

                {
                    customModals.map((customModal: any, index: any) => (
                        <div className='flex' key={index}>
                            {customModal.modal}
                        </div>
                    ))}
            </div>
            {addButtonVisible && (
                <div className='add-container'>
                    <Button className="add-button" type="button" onClick={() => { setMode("create"); openModal({}); }} icon={<PlusCircle size={80} />}>
                    </Button>
                </div>
            )}
        </div>
    );
}
