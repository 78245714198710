
import Table from "./components/Table";
import PermissionModal from './components/PermissionModal';

import { getData, deleteData } from './services/api';
import { useSession } from './assets/auth/SessionContext';
import { useEffect, useState } from "react";


const Permissions = () => {
    const { session } = useSession();

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [type] = useState("permissions")

    async function remove(ref: string) {
        try {
            await deleteData(session, `permissions/${ref}`, null)

            return { success: true, message: "Permission removed" };
        } catch (err: any) {
            return { success: false, message: err.message };
        }
    }

    useEffect(() => {

        getData(session, `permissions`, null).then(roleData => {
            setData(roleData)
            setIsLoading(false);
        }).catch(err => {
            setError(err);
            setIsLoading(false);
        });
    }, []);

    if (isLoading || (!data && !error)) {
        // Render loading indicator or placeholder while data is being fetched
        return (
            <div className="flex">
                <div className="table-container">

                </div>
            </div>
        )
    }

    return (
        <div className="flex">
            <div className="table-container">
                <Table data={data} error={error} type={type} remove={remove} modal={<PermissionModal />} />
            </div>
        </div>
    )
}

export default Permissions; 