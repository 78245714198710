import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { postData } from "../services/api";

import { useSession } from '../assets/auth/SessionContext';

export default function CashOutModal(modalData: any) {

    const { session } = useSession();

    const [formData] = useState(modalData.selectedDataItem);
    const [modalHeader, setModalHeader] = useState("");
    const [description, setDescription] = useState("");

    const toast = useRef<Toast>(null);

    useEffect(() => {
        setModalHeader("Cash Out Details");
    }, []);


    const handleDescriptionChange = (e: any) => {
        const { value } = e.target;
        setDescription(value);
    }

    const handleCashOut = () => {

        try {

            if (!description) {
                throw new Error("Description is required")
            }

            const body = {
                ...formData,
                description,
                clientUserRef: session?.user?.username,
                clientUserDisplayName: session?.user?.displayName
            }

            postData(session, "cash-out", body, null).then(cashOut => {
                modalData.closeModal({ toast: true, message: "Cash out complete" });
            }).catch(err => {
                if (toast.current) {
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                }
            });
        } catch (error: any) {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: error.message });
            }
        }
    }

    return (
        <div className="modal-container">
            <Toast ref={toast} />
            <div className="modal">
                <div className="modal-header">
                    <span className="modal-title">{modalHeader}</span>
                    <span className="modal-close" onClick={modalData.closeModal}>&times;</span>
                </div>
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">From</label>
                        <input
                            className="text-input"
                            readOnly={true}
                            type="text"
                            id="ref"
                            name="ref"
                            value={dayjs(formData.cashOutPeriodFrom).format("DD-MM-YYYY HH:mm")}
                        />
                    </div>

                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">To</label>
                        <input
                            className="text-input"
                            readOnly={true}
                            type="text"
                            id="ref"
                            name="ref"
                            value={dayjs(formData.cashOutPeriodTo).format("DD-MM-YYYY HH:mm")}
                        />
                    </div>
                    <div className="flex">
                        <div className="flex-1 flex m-2 px-5 py-3 " style={{ visibility: "hidden" }}>
                            {/* this is here on purpose so it takes the necessary space */}
                            <label className="text-label" htmlFor="ref">Cash out difference - €</label>
                            <input
                                className="text-input"
                                readOnly={true}
                                type="text"
                                id="ref"
                                name="ref"
                                value={formData.cashOutDifference}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Tickets Sold - €</label>
                        <input
                            className="text-input"
                            readOnly={true}
                            type="text"
                            id="ref"
                            name="ref"
                            value={formData.combinedTotalTicketsSoldEuro}
                        />
                    </div>

                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Tickets Sold (Exc. VAT) - €</label>
                        <input
                            className="text-input"
                            readOnly={true}
                            type="text"
                            id="ref"
                            name="ref"
                            value={formData.combinedTotalTicketsSoldEuroExcVAT}
                        />
                    </div>

                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Tickets Sold cash - €</label>
                        <input
                            className="text-input"
                            readOnly={true}
                            type="text"
                            id="ref"
                            name="ref"
                            value={formData.combinedTotalTicketsSoldCashEuro}
                        />
                    </div>
                </div>

                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Tickets card - €</label>
                        <input
                            className="text-input"
                            readOnly={true}
                            type="text"
                            id="ref"
                            name="ref"
                            value={formData.combinedTotalTicketsSoldCardEuro}
                        />
                    </div>

                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Tickets payment link - €</label>
                        <input
                            className="text-input"
                            readOnly={true}
                            type="text"
                            id="ref"
                            name="ref"
                            value={formData.combinedTotalTicketsSoldPaymentLink}
                        />
                    </div>

                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Commission - €</label>
                        <input
                            className="text-input"
                            readOnly={true}
                            type="text"
                            id="ref"
                            name="ref"
                            value={formData.combinedTotalCommission}
                        />
                    </div>
                </div>

                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Cash out difference - €</label>
                        <input
                            className="text-input"
                            readOnly={true}
                            type="text"
                            id="ref"
                            name="ref"
                            value={formData.cashOutDifference}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Description</label>
                        <textarea
                            className="text-input-multiline"
                            id="description"
                            name="description"
                            onChange={handleDescriptionChange}
                        />
                    </div>
                    <div className="flex">
                        <div className="flex-1 flex m-2 px-5 py-3 " style={{ visibility: "hidden" }}>
                            {/* this is here on purpose so it takes the necessary space */}
                            <label className="text-label" htmlFor="ref">Cash out difference - €</label>
                            <input
                                className="text-input"
                                readOnly={true}
                                type="text"
                                id="ref"
                                name="ref"
                                value={formData.cashOutDifference}
                            />
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <button className="save-button" type="button" onClick={handleCashOut}>Cash Out</button>
                    <button type="button" className="cancel-button" onClick={modalData.closeModal}>Cancel</button>
                </div>
            </div>
        </div>
    );
}
