const config = {
    // apiAddress: "http://localhost:8442/tdit/", // local
    // apiAddress: "http://18.157.126.255:8442/tdit/", // dev
    apiAddress: "tdit/",  //prod
    tokenGeneration: {
        key: "XREmSYlhkJkEBLC5kfQktw==",
        grant_type: "password",
        scopes: "admin"
    }
}

export { config }