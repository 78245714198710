import { useEffect, useState, useRef } from "react";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Image } from 'primereact/image';

import { getData, postData } from "./services/api";
import { useSession } from "./assets/auth/SessionContext";

import GenderDistribution from './GenderDistribution';
import TicketDistributionChart from "./TicketDistributionChart";
import TicketBarGraph from "./TicketBarGraph";

import dayjs from "dayjs";
import datePicker from "./utils/date-picker";

interface TicketSoldData {
    ref: string,
    image: string;
    name: string;
    location: string,
    genderTracking: boolean,
    dateFrom: string,
    tracking: {
        gender: {
            female: number,
            male: number,
            other: number
        },
        ticketsSold: number
    },
    capacity: number,
    totalRevenue: number
}

interface TicketData {
    _id: string;
    name: string;
    price: number;
    ticketCapacity: number;
    status: string;
    tracking?: {
        ticketsSold: number;
    };
    isComplimentary?: boolean;
    totalRevenue: number;
}

const TicketSales = () => {
    type ExpandedEventType = { [key: string]: React.ReactNode | null };
    type LoadingStateType = { [key: string]: boolean };

    const dateFormat = 'dd-mm-yy';
    const dateFormatText = "DD-MM-YYYY"

    const toast = useRef<Toast>(null);

    const [dateFrom, setDateFrom] = useState<Date>(new Date());
    const [dateTo, setDateTo] = useState<Date>(new Date(dayjs().add(1, "month").valueOf()));
    const [selectedEventCategory, setSelectedEventCategory] = useState(null);
    const [eventCategories, setEventCategories] = useState([]);
    const [expandedEvent, setExpandedEvent] = useState<ExpandedEventType>({});
    const [data, setData] = useState<TicketSoldData[]>([]);
    const [openItem, setOpenItem] = useState<string | null>(null); // Track the open item ref
    const [loading, setLoading] = useState<boolean>(false); // Loading state
    const [loadingState, setLoadingState] = useState<LoadingStateType>({}); // Loading state for each container

    const { session } = useSession();

    const setDate = (fieldName: string, value: Date) => {
        datePicker.max1Month({ fieldName, value, from: dateFrom, to: dateTo, setFrom: setDateFrom, setTo: setDateTo });
    };

    useEffect(() => {
        // Clear data and reset selected event when date range changes
        setData([]);
        setSelectedEventCategory(null);
    }, [dateFrom, dateTo]);

    useEffect(() => {
        getData(session, `event-categories`, "dropdown=true").then(eventCategories => {
            const eventCategoriesForMultiSelect = eventCategories.map((x: { name: any; ref: any }) => ({
                name: x.name,
                value: x.ref
            }));

            setEventCategories(eventCategoriesForMultiSelect);
        });
    }, []);

    const handleSelectedEventCategory = (data: any) => {
        try {
            setSelectedEventCategory(data);
            setLoading(true); // Set loading to true when starting to fetch data

            postData(session, `reports/track-tickets-sold`, { dateFrom: dateFrom, dateTo: dateTo, eventCategoryRef: data }, null).then(reportData => {
                setData(reportData?.data || []);
                setLoading(false); // Set loading to false after data is fetched
                return;
            }).catch(err => {
                setLoading(false); // Set loading to false in case of error
                if (toast.current) {
                    toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                }
            });
        } catch (err) {
            setLoading(false); // Set loading to false in case of error
            // Handle error if needed
        }
    }

    const handleButtonClick = (ref: string) => {
        if (openItem === ref) {
            setOpenItem(null);
            setExpandedEvent(prev => ({ ...prev, [ref]: null }));
            setLoadingState(prev => ({ ...prev, [ref]: false }));
            return;
        }

        setOpenItem(ref);
        setLoadingState(prev => ({ ...prev, [ref]: true }));

        getData(session, `reports/track-ticket-types-sold`, `eventRef=${ref}`).then((ticketTypes: TicketData[]) => {

            const ticketElements = <TicketBarGraph data={ticketTypes} />

            setExpandedEvent(prev => ({ ...prev, [ref]: <div>{ticketElements}</div> }));
            setLoadingState(prev => ({ ...prev, [ref]: false }));
        }).catch(err => {
            setLoadingState(prev => ({ ...prev, [ref]: false }));
        });
    };

    return (
        <div className="flex">
            <Toast ref={toast} />
            <div className="table-container">
                <div className="card flex flex-wrap gap-3 p-fluid select-dates">
                    <div className="flex1">
                        <label htmlFor="buttondisplay" className="font-bold block mb-2">
                            From
                        </label>
                        <Calendar dateFormat={dateFormat} className="calendar" value={dateFrom} onChange={(e) => setDate("from", e.value || new Date())} showIcon />
                    </div>
                    <div className="flex1">
                        <label htmlFor="buttondisplay" className="font-bold block mb-2">
                            To
                        </label>
                        <Calendar value={dateTo} dateFormat={dateFormat} className="calendar" onChange={(e) => setDate("to", e.value || new Date())} showIcon />
                    </div>
                    <div className="flex1 top-bar-filtering">
                        <label htmlFor="text-label" className="font-bold block mb-2">Select Event Category</label>
                        <Dropdown
                            onChange={(e) => { handleSelectedEventCategory(e.value); }}
                            options={eventCategories}
                            optionLabel="name"
                            placeholder="Select Event Category"
                            className="dropdown"
                            value={selectedEventCategory}
                        />
                    </div>
                </div>

                {loading ? (
                    <div>Loading data, please wait...</div>
                ) : (
                    data.map((result, index) => (
                        <div className="ticket-sales-repeat" key={index}>
                            <div className="flex">
                                <div className="arrow-container">
                                    <button className="arrow-button" onClick={() => handleButtonClick(result.ref)}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            className={`arrow-icon ${openItem === result.ref ? 'rotated' : ''}`}
                                        >
                                            <path d="M8.59 16.34L12 12.92l3.41 3.42L17 15l-5-5-5 5z" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="flex m-2 px-5 py-3 image-container">
                                    <Image
                                        src={result.image}
                                        zoomSrc={result.image}
                                        alt="Image"
                                        max-width="250"
                                        max-height="230"
                                        preview />
                                </div>
                                <div className="flex-1 flex flex-col m-2 px-5 py-3 select-time">
                                    <div className="label-grid">
                                        <label className="text-label mb-2" htmlFor="name">Name</label>
                                        <span>{result.name}</span>

                                        <label className="text-label mb-2" htmlFor="name">Location</label>
                                        <span>{result.location}</span>

                                        <label className="text-label mb-2" htmlFor="name">Date</label>
                                        <span>{dayjs(result.dateFrom).format(dateFormatText)}</span>
                                    </div>
                                </div>
                                {result.genderTracking && (
                                    <div className="flex-1 flex flex-col m-2 px-5 py-3 select-time">
                                        <GenderDistribution gender={result.tracking.gender} capacity={result.capacity} totalRevenue={result.totalRevenue} />
                                    </div>
                                )}
                                {!result.genderTracking && (
                                    <div className="flex-1 flex flex-col m-2 px-5 py-3 select-time">
                                        <TicketDistributionChart capacity={result.capacity} totalTicketsSold={result.tracking.ticketsSold} totalRevenue={result.totalRevenue} />
                                    </div>
                                )}
                            </div>
                            {openItem === result.ref && (
                                <div className="expandable">
                                    {loadingState[result.ref] ? (
                                        <div>Loading data, please wait...</div>
                                    ) : (
                                        expandedEvent[result.ref]
                                    )}
                                </div>
                            )}
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default TicketSales;
