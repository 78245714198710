
import Table from "./components/Table";
import TicketTypeModal from './components/TicketTypeModal';

import { getData, deleteData } from './services/api';
import { useSession } from './assets/auth/SessionContext';
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from "primereact/button";


const TicketTypes = () => {
    const { session } = useSession();
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const eventRef = queryParams.get('eventRef');
    const eventName = queryParams.get('eventName');


    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [type] = useState("ticketTypes")

    async function remove(ref: string) {
        try {
            await deleteData(session, `ticket-types/${ref}`, null)
            return { success: true, message: "User removed" };
        } catch (err: any) {
            return { success: false, message: err.message };
        }
    }

    const additionalData = {
        eventName: eventName,
        eventRef: eventRef
    }

    useEffect(() => {

        getData(session, `ticket-types`, `eventRef=${eventRef}`).then(userData => {
            setData(userData)
            setIsLoading(false);
        }).catch(err => {
            setError(err);
            setIsLoading(false);
        });
    }, []);

    if (isLoading || (!data && !error)) {
        // Render loading indicator or placeholder while data is being fetched
        return (
            <div className="flex">
                <div className="table-container">

                </div>
            </div>
        )
    }

    return (
        <div className="flex">
            <div className="table-container">
                <div className="ticket-type-container">
                    <div className="left">
                        <label htmlFor="buttondisplay" className="font-bold block mb-2">
                            Selected Event: {eventName}
                        </label>
                    </div>
                    <div className="right">
                        <div className="flex">
                            <div className="auto-margin-left">
                                <Button className="add-button" tooltip="Link" type="button" onClick={() => { }} icon="pi pi-link">                                </Button>
                            </div>
                            <div>
                                <button className="save-button" type="button" onClick={() => {
                                    navigate("/events")
                                }
                                }>Back To Events</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Table data={data} error={error} type={type} remove={remove} additionalData={additionalData} modal={<TicketTypeModal />} />
                </div>
            </div>
        </div>
    )
}

export default TicketTypes; 