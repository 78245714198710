
import SideMenu from './MainLayout';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';

const Layout = () => {

    const [expanded, setExpanded] = useState(true);

    return (
        <div className="layout">
            <SideMenu setExpandedState={setExpanded} />
            <div className={`main-content ${expanded ? 'ml-80' : 'ml-24'}`}>
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;