import { useEffect, useState } from 'react';
import { KeyRound, User } from "lucide-react";

import "./assets/auth/login.css"

import { useSession } from './assets/auth/SessionContext';

export default function Login() {

  // const [username, setUsername] = useState("bricksu");
  // const [password, setPassword] = useState("Password123!");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { login, error } = useSession();

  function handleKeyUp(event: any) {

    if (username && password) {
      if (event.keyCode === 13) { // Enter
        login({ username: username, password: password });
      }
    }
  }


  return (
    <form onKeyUp={handleKeyUp} >
      <div className='login-container'>
        <div className='header'>
          <div className='text'>Sign in</div>
          <div className='underline'></div>
        </div>
        <div className='inputs'>
          <div className='input'>
            <div className='icon-image' > <User size={32} /></div>
            <input type='text' placeholder='Username' onChange={(e) => setUsername(e.target.value)}></input>
          </div>
          <div className='input'>
            {/* <img src={password_icon} alt=''></img> */}
            <div className='icon-image' > <KeyRound size={32} /></div>
            <input type='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)}></input>
          </div>
        </div>
        {/* <div className="forgot-password">Lost Password? <span>Click Here!</span></div> */}
        <div className="submit-container">
          <div className="submit" onClick={() => login({ username: username, password: password })}>Login</div>
        </div>
        <div className="error-handling"><span>{error}</span></div>
      </div>
    </form>
  )
}


