import { Toast } from "primereact/toast";
import { useSession } from "../assets/auth/SessionContext";
import { useEffect, useRef, useState } from "react";
import { postData } from "../services/api";

export default function TicketResendModal(modalData: any) {

    const { session } = useSession();

    const [formData, setFormData] = useState(modalData.selectedDataItem);
    const [modalHeader, setModalHeader] = useState("");

    const toast = useRef<Toast>(null);

    useEffect(() => {
        setModalHeader("Re-Send ticket")
    }, []);

    const handleEmailChange = (e: any) => {
        const { value } = e.target;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            email: value
        }));
    }

    const handleResend = (data: any) => {
        postData(session, `tickets/resend`, { ref: formData.ref, email: formData.email }, null).then(resend => {

            modalData.closeModal({ severity: "success", summary: `E-Mail sent to ${formData.email}` });


        }).catch(err => {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
            }
        });
    }

    return (
        <div className="modal-container">

            <Toast ref={toast} />
            <div className="modal">
                <div className="modal-header">
                    <span className="modal-title">{modalHeader}</span>
                    <span className="modal-close" onClick={modalData.closeModal}>&times;</span>
                </div>
                <div className="flex margin-top-20 visible-button">
                    <label className="text-label" htmlFor="email">E-Mail</label>
                    <input
                        className="text-input"
                        type="text"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleEmailChange}
                    />
                </div>
                <div className="footer">
                    <button type="button" className="save-button" onClick={handleResend} >Send</button>
                    <button type="button" className="cancel-button" onClick={modalData.closeModal}>Cancel</button>
                </div>
            </div>
        </div>
    );
}
