import { Toast } from "primereact/toast";
import { useSession } from "../assets/auth/SessionContext";
import { useEffect, useRef, useState } from "react";
import { postData, putData } from "../services/api";
import { InputSwitch } from "primereact/inputswitch";

export default function TicketTypeGroupModal(modalData: any) {

    const [formData, setFormData] = useState(modalData.selectedDataItem);
    const [abbreviationEditable, setAbbreviationEditable] = useState(false);
    const [modalHeader, setModalHeader] = useState("");
    const [isComplimentary, setIsComplimentary] = useState(false);

    const toast = useRef<Toast>(null);

    const { session } = useSession();

    function save(data: any) {
        try {

            if (!data.ref) {
                throw new Error("Abbreviation is required")
            }

            if (!data.name) {
                throw new Error("Name is required")
            }

            let ticketTypeGroup = {
                ref: data.ref,
                name: data.name,
                description: data.description,
                isComplimentary: isComplimentary
            } as any;


            if (modalData.mode === "update") {
                putData(session, `ticket-type-groups/${ticketTypeGroup.ref}`, ticketTypeGroup, null).then(updateTicketTypeGroupData => {

                    modalData.closeModal({ toast: true, message: `${ticketTypeGroup.name} updated`, updateRecord: ticketTypeGroup });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'Ticket type group created', detail: "" });
                    }

                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }
                });
            } else if (modalData.mode === "create") {

                postData(session, `ticket-type-groups`, ticketTypeGroup, null).then(newTicketTypeGroupData => {

                    modalData.closeModal({ toast: true, message: `${ticketTypeGroup.name} created`, newRecord: newTicketTypeGroupData });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'Ticket type group created', detail: "" });
                    }

                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }
                });
            }
        } catch (error: any) {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: error.message });
            }
        }
    }

    useEffect(() => {
        if (modalData.mode === "create") {
            setAbbreviationEditable(true)
            setModalHeader("Creating Ticket type group")
        } else {
            setAbbreviationEditable(false)
            setModalHeader("Updating Ticket type group")
        }

        setIsComplimentary(formData.isComplimentary);
    }, []);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    return (
        <div className="modal-container">

            <Toast ref={toast} />
            <div className="modal ">
                <div className="modal-header">
                    <span className="modal-title">{modalHeader}</span>
                    <span className="modal-close" onClick={modalData.closeModal}>&times;</span>
                </div>
                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Abbreviation</label>
                        <input
                            className="text-input"
                            readOnly={!abbreviationEditable}
                            type="text"
                            id="ref"
                            name="ref"
                            value={formData.ref}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="name">Name</label>
                        <input
                            className="text-input"
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="description">Description</label>
                        <textarea
                            className="text-input-multiline"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Complimentary?</label>
                        <InputSwitch
                            checked={isComplimentary}
                            onChange={(e) => setIsComplimentary(e.value)}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">

                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3">

                    </div>
                </div>

                <div className="footer">
                    <button className="save-button" type="button" onClick={() => {
                        save(formData);
                    }
                    }>Save</button>
                    <button type="button" className="cancel-button" onClick={modalData.closeModal}>Cancel</button>
                </div>
            </div>
        </div>
    );
}