import imageCompression from "browser-image-compression";

export async function upload(event: any) {
    try {

        const file = event.files[0];

        const options = {
            maxSizeMB: 0.2,           // Maximum size in MB (200 KB)
            maxWidthOrHeight: 1920,   // Maximum width or height to retain quality
            useWebWorker: true,       // Use web workers for better performance
        };

        const compressedFile = await imageCompression(file, options);

        let blob = await fetch(URL.createObjectURL(compressedFile)).then((r) => r.blob());

        const reader = new FileReader();

        reader.readAsDataURL(blob);

        return new Promise((resolve, reject) => {
            reader.onloadend = function () {
                const base64data = reader.result;

                if (base64data) {
                    return resolve(base64data.toString());
                }

                return reject(new Error("Image not uploaded"));
            };
        });

    } catch (error) {
        throw error;
    }
}