
import { useSession } from './assets/auth/SessionContext';

export default function Home() {
    const { session } = useSession();

    const hours = new Date().getHours();
    let greeting;

    if (hours < 12) {
        greeting = 'Good morning';
    } else if (hours < 18) {
        greeting = 'Good afternoon';
    } else {
        greeting = 'Good evening';
    }

    return (
        <div className="welcome-container">
            <div className="welcome-box">
                <div className="welcome-message">
                    <span>Welcome to TDITicketing backend</span>
                </div>
                <div className="greeting-message">
                    <span>{`${greeting}, ${session?.user?.displayName}`}</span>
                </div>
            </div>
        </div>
    );
}