import React from 'react';

interface GenderDistributionChartProps {
    gender: {
        female: number;
        male: number;
        other: number;
    };
    capacity: number;
    totalRevenue: number;
}

const GenderDistributionChart: React.FC<GenderDistributionChartProps> = ({ gender, capacity, totalRevenue }) => {
    const total = gender.female + gender.male + gender.other;
    const remaining = capacity - total; // Calculate remaining capacity

    // Define colors for each segment
    const colors = {
        female: '#ff85b2',
        male: '#017BFF',
        other: '#9acd32',
        remaining: '#d3d3d3'
    };

    const radius = 80;
    const strokeWidth = 40;
    const circumference = 2 * Math.PI * radius;
    const strokeDasharray = `${circumference} ${circumference}`;
    const rotationOffset = -90;

    // Calculate the rotation offsets for each segment
    const femaleOffset = rotationOffset;
    const maleOffset = rotationOffset + (360 * (gender.female / capacity));
    const otherOffset = rotationOffset + (360 * (gender.female / capacity) + 360 * (gender.male / capacity));
    const remainingOffset = rotationOffset + (360 * (gender.female / capacity) + 360 * (gender.male / capacity) + 360 * (gender.other / capacity));

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Chart */}
            <svg width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                {/* Remaining Capacity Segment */}
                <circle
                    cx="100"
                    cy="100"
                    r={radius}
                    stroke={colors.remaining}
                    strokeWidth={strokeWidth}
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={circumference * (1 - (remaining / capacity))}
                    fill="none"
                    transform={`rotate(${remainingOffset} 100 100)`}
                />
                {/* Female Segment */}
                <circle
                    cx="100"
                    cy="100"
                    r={radius}
                    stroke={colors.female}
                    strokeWidth={strokeWidth}
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={circumference * (1 - (gender.female / capacity))}
                    fill="none"
                    transform={`rotate(${femaleOffset} 100 100)`}
                />
                {/* Male Segment */}
                <circle
                    cx="100"
                    cy="100"
                    r={radius}
                    stroke={colors.male}
                    strokeWidth={strokeWidth}
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={circumference * (1 - (gender.male / capacity))}
                    fill="none"
                    transform={`rotate(${maleOffset} 100 100)`}
                />
                {/* Other Segment */}
                <circle
                    cx="100"
                    cy="100"
                    r={radius}
                    stroke={colors.other}
                    strokeWidth={strokeWidth}
                    strokeDasharray={strokeDasharray}
                    strokeDashoffset={circumference * (1 - (gender.other / capacity))}
                    fill="none"
                    transform={`rotate(${otherOffset} 100 100)`}
                />
                {/* Center Text */}
                <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="16" fill="#000">
                    {total > 0 ? `${total}/${capacity}` : 'No Data'}
                </text>
            </svg>

            {/* Legend */}
            <div style={{ marginLeft: '20px' }}>
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: colors.female, marginRight: '8px' }}></span>
                    Female: {gender.female}
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: colors.male, marginRight: '8px' }}></span>
                    Male: {gender.male}
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: colors.other, marginRight: '8px' }}></span>
                    Other: {gender.other}
                </div>
                <div>
                    <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: colors.remaining, marginRight: '8px' }}></span>
                    Remaining: {remaining}
                </div>
                <div style={{ marginTop: '20px' }}>
                    Total revenue: €{totalRevenue}
                </div>
            </div>
        </div >
    );
};

export default GenderDistributionChart;
