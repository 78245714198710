import ReactDOM from 'react-dom/client';
import App from "./App";

import 'primereact/resources/themes/saga-blue/theme.css'; // Replace with your desired theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import "./assets/modal.css";
import "./assets/index.css"

import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);