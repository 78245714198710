import { Toast } from "primereact/toast";
import { useSession } from "../assets/auth/SessionContext";
import { useEffect, useRef, useState } from "react";
import { postData, putData } from "../services/api";
import { Dropdown } from "primereact/dropdown";

export default function RouteResourceModal(modalData: any) {

    const [formData, setFormData] = useState(modalData.selectedDataItem);
    const [modalHeader, setModalHeader] = useState("");
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [methods, setMethods] = useState<{ name: string; value: string; }[]>([]);

    const toast = useRef<Toast>(null);

    const { session } = useSession();

    function save(data: any) {
        try {

            if (!data.name) {
                throw new Error("Name is required")
            }

            if (!data.route) {
                throw new Error("Route resource is required")
            }

            if (!selectedMethod) {
                throw new Error("Method is required")
            }

            let routeResource = {
                ref: data.ref,
                name: data.name,
                route: data.route,
                method: selectedMethod
            } as any;


            if (modalData.mode === "update") {
                putData(session, `route-resources/${routeResource.ref}`, routeResource, null).then(updateRouteResourceData => {

                    modalData.closeModal({ toast: true, message: `${routeResource.name} updated`, updateRecord: routeResource });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'Route Resource created', detail: "" });
                    }

                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }
                });
            } else if (modalData.mode === "create") {

                postData(session, `route-resources`, routeResource, null).then(newRouteResourceData => {

                    modalData.closeModal({ toast: true, message: `${routeResource.name} created`, newRecord: newRouteResourceData });

                    if (toast.current) {
                        toast.current.show({ severity: 'success', summary: 'Route Resource created', detail: "" });
                    }

                    return;

                }).catch(err => {
                    if (toast.current) {
                        toast.current.show({ severity: 'error', summary: 'Error Message', detail: err.message });
                    }
                });
            }
        } catch (error: any) {
            if (toast.current) {
                toast.current.show({ severity: 'error', summary: 'Error Message', detail: error.message });
            }
        }
    }

    useEffect(() => {
        if (modalData.mode === "create") {
            setModalHeader("Creating Route resource")
        } else {
            setModalHeader("Updating Route resource")
        }

        const methodTypes = [
            {
                name: "POST",
                value: "POST"
            },
            {
                name: "GET",
                value: "GET"
            },
            {
                name: "DELETE",
                value: "DELETE"
            },
            {
                name: "PUT",
                value: "PUT"
            }
        ];

        setMethods(methodTypes);
        setSelectedMethod(formData.method);
    }, []);


    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    return (
        <div className="modal-container">

            <Toast ref={toast} />
            <div className="modal ">
                <div className="modal-header">
                    <span className="modal-title">{modalHeader}</span>
                    <span className="modal-close" onClick={modalData.closeModal}>&times;</span>
                </div>

                <div className="flex">
                    <div className="flex-1 flex m-2 px-5 py-3">
                        <label className="text-label" htmlFor="name">Name</label>
                        <input
                            className="text-input"
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="ref">Route</label>
                        <input
                            className="text-input"
                            type="text"
                            id="route"
                            name="route"
                            value={formData.route}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="flex-1 flex m-2 px-5 py-3 ">
                        <label className="text-label" htmlFor="level">Method</label>
                        <Dropdown
                            onChange={(e) => { setSelectedMethod(e.value); }}
                            options={methods}
                            optionLabel="name"
                            placeholder="Select method"
                            className="dropdown"
                            value={selectedMethod} />
                    </div>
                </div>

                <div className="footer">
                    <button className="save-button" type="button" onClick={() => {
                        save(formData);
                    }
                    }>Save</button>
                    <button type="button" className="cancel-button" onClick={modalData.closeModal}>Cancel</button>
                </div>
            </div>
        </div>
    );
}